import React, { ReactNode } from 'react';
import map from 'lodash/map';
import GlobalStyles from 'theme/GlobalStyle';
import CookieBar from './cookieBar/index';
import SEO from './SEO/seo';
import 'theme/index.css';
import 'theme/reset.css';

interface SEOprops {
  lang?: string | 'en';
  noIndex?: boolean;
  extraTags?: Record<string, unknown> | Array<Record<string, unknown>>;
  canonical: string;
  author?: string;
  image?: string;
  children?: ReactNode;
  metaTitle: string;
  metaDescription: string;
  hideFooter?: boolean;
  hideCookieBanner?: boolean;
  blockScrollX?: boolean;
  hideHeader?: boolean;
}

const Layout: React.FC<SEOprops> = ({
  children,
  metaTitle,
  metaDescription,
  canonical,
  extraTags,
  hideCookieBanner = false,
  image,
  blockScrollX,
  noIndex,
}) => {
  const noIndexPage = process.env.GATSBY_ENV !== 'PROD' || noIndex;

  let extra;
  if (extraTags) {
    extra = map(extraTags, (tag, i) => <meta {...tag} key={i} />);
  }

  return (
    <>
      <GlobalStyles blockScrollX={blockScrollX} />
      <SEO
        description={metaDescription}
        title={metaTitle}
        noIndex={noIndexPage}
        extraTags={extra}
        canonical={canonical}
        image={image}
      />{' '}
      {children}
      {!hideCookieBanner && <CookieBar />}
    </>
  );
};

export default Layout;
