import React, { useState, useRef } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest, {
  OnSuggestionSelected,
  RenderInputComponent,
  RenderSuggestion,
  SuggestionsFetchRequested,
  OnSuggestionsClearRequested,
} from 'react-autosuggest';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { BasicDoc, Hit } from 'react-instantsearch-core';
import { FormattedIntegrations } from 'hooks/useFormattedIntegrations';
import CustomHighlight from './customHighlight';

interface HitRecord {
  categories?: string[];
  desc?: string;
  title?: string;
  madeBy?: string;
  link?: string;
  objectID?: string;
}

export const StyledA = styled.a`
  width: 100%;
  display: flex;
  padding: 16px 24px;
  background-color: #fff;
  border-bottom: 1px solid #e4e5e8;
  align-items: center;
  transition: none;
  & img {
    margin-right: 24px;
    width: 32px;
    height: 32px;
    ${media.tablet} {
      margin-right: 16px;
    }
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: #f6f8f9;
  }
  ${media.tablet} {
    padding: 0 24px;
  }
`;

const StyledBox = styled.div`
  display: flex;
  min-height: auto;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${media.tablet} {
    padding: 16px;
  }
  li {
    list-style: none;
  }
  p:nth-of-type(1) {
    margin-bottom: 0;
    font-size: 16px;
    text-align: left;
    ${media.tablet} {
      line-height: normal;
      width: 100%;
      margin-bottom: 4px;
    }
    span {
      font-size: 14px;
      font-weight: 900;
      color: #0a4ed6;
      ${media.tablet} {
        font-size: 14px;
      }
    }
    mark {
      ${media.tablet} {
        font-size: 14px;
      }
    }
  }
  p:nth-of-type(2) {
    font-weight: 600;
    color: #000;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    text-align: left;
    line-height: normal;
    ${media.tablet} {
      font-size: 12px;
      line-height: 18px;
      width: 100%;
    }
    span {
      font-size: 12px;
    }
    mark {
      font-weight: 600;
      font-size: inherit;
      ${media.tablet} {
        font-size: 12px;
      }
    }
  }
`;

const StyledLabel = styled.label`
  color: #a9a9a9;
  margin: 0;
  font-family: 'searchicon' !important;
  &::before {
    content: '\\e900';
    color: #000;
    position: absolute;
    padding: 16px;
    line-height: 100%;
  }
`;

const StyledInput = styled.input`
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding-left: 48px;
  border: 2px solid #000;
  border-radius: 4px;
  font-size: 16px;
  -webkit-appearance: none;
  &:focus {
    border: 2px solid #0a4ed6;
  }
  &::placeholder {
    color: #999;
    font-size: 16px;
  }
`;

export const StyledDivInput = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  margin: 0 auto 40px;
  background-color: #fff;
  &:focus-within {
    ${StyledLabel} {
      &::before {
        color: #0a4ed6;
      }
    }
  }
  ${media.tablet} {
    width: 100%;
    margin: 0 auto 48px;
  }
`;

interface AutoCompleteProps {
  hits: Array<Hit<BasicDoc>>;
  currentRefinement: string;
  refine: (arg?: string) => void;
  onSuggestionSelected: OnSuggestionSelected<HitRecord>;
  onSuggestionCleared: OnSuggestionsClearRequested;
  integrations: FormattedIntegrations[];
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  hits,
  currentRefinement,
  refine,
  onSuggestionSelected,
  onSuggestionCleared,
  integrations,
}) => {
  const [inputValue, setInputValue] = useState(currentRefinement || '');
  const [focused, setFocused] = useState(false);

  const input = useRef(null);

  const onChange = (_: React.FormEvent<HTMLElement>, item: { newValue: string }) => {
    if (!item.newValue) {
      onSuggestionCleared();
    }
    setInputValue(item.newValue);
  };

  const onBlur = () => setFocused((prevState) => !prevState);

  const onFocus = () => setFocused((prevState) => !prevState);

  const checkHits = () => {
    let isHitsLoaded = null;
    if (hits.length === 0) {
      isHitsLoaded = true;
      if (inputValue) {
        isHitsLoaded = false;
        return isHitsLoaded;
      }
      return isHitsLoaded;
    }
    if (hits.length !== 0) {
      isHitsLoaded = false;
      return isHitsLoaded;
    }
    return false;
  };

  const renderInputComponent: RenderInputComponent = (inputProps) => {
    return (
      <StyledDivInput className="styledInput">
        <StyledLabel htmlFor="search" />
        <StyledInput
          {...inputProps}
          ref={input}
          role="combobox"
          aria-label="searchbar"
          disabled={checkHits()}
        />
      </StyledDivInput>
    );
  };

  const onSuggestionsFetchRequested: SuggestionsFetchRequested = ({ value }) => refine(value);

  const onSuggestionsClearRequested = () => refine();

  const getSuggestionValue = (hit: Hit) => {
    return hit.title;
  };

  const renderSuggestion: RenderSuggestion<Hit> = (hit: Hit) => {
    const { link } = hit;
    const image = integrations.filter((item: FormattedIntegrations) => item.link === link);
    return (
      <StyledA href={link} className="articlepreview">
        <img src={image[0].icon} alt="logo" />
        <StyledBox>
          <CustomHighlight hit={hit} attribute="title" />
          <CustomHighlight hit={hit} attribute="categories[0]" />
        </StyledBox>
      </StyledA>
    );
  };

  const inputProps = {
    placeholder: 'Search for apps',
    focused, // check if it should be false
    onChange,
    onBlur,
    onFocus,
    value: inputValue,
  };

  return (
    hits && (
      <AutoSuggest
        suggestions={hits}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
      />
    )
  );
};

const Autocomplete = connectAutoComplete(AutoComplete);

export default Autocomplete;
