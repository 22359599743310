import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const InstalledApps = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 28px 30px 20px;
  position: sticky;
  background-color: #fff;
  z-index: 99;
  top: 0;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
  }
  & p {
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
  }
  & a {
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    color: #0446ca;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 1575px) {
    &::after {
      width: calc(100% - 30px);
      transform: translateX(15px);
    }
  }
  ${media.mobile} {
    padding: 28px 15px 20px;
    p {
      font-size: 16px;
    }
  }
`;
