import styled from 'styled-components';
import { media } from 'theme/breakpoints';

interface GridBoxProps {
  hideTitle?: boolean;
}

export const GridBox = styled.section<GridBoxProps>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-top: ${(props) => (props.hideTitle ? 0 : '48px')};
  padding-bottom: 0;
  &.loading {
    grid-template-columns: 1fr;
    margin-top: 80px;
  }
  ${media.desktopWide} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const Section = styled.section`
  &.container {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Main = styled.main`
  padding: 66px 30px;
`;
