import { createGlobalStyle, DefaultTheme } from 'styled-components';
import { media } from 'theme/breakpoints';
import arrowDropdown from 'fonts/arrowMenu/icomoon.woff';
import searchicon from 'fonts/searchbar/icomoon.woff';

interface GlobalProps extends DefaultTheme {
  blockScrollX?: boolean;
}

const GlobalStyle = createGlobalStyle<GlobalProps>`

#___gatsby{
overflow-x: ${({ blockScrollX }) => (blockScrollX ? 'hidden' : 'initial')};
}

.container {
   max-width: 1200px;
   width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

section{
  padding: 7rem 0;
  ${media.desktop}{
    padding: 5rem 0;
  }
}

body{
  font-weight: 400;
  font-family: 'Muli', sans-serif !important;
  background-color: #fff;
}

 .modal {
    z-index: 9999;
}

& a{
  text-decoration: none;
  color: #0346c9;
}

h1,h2,h3,h4,h5,h6{
  font-weight: 800;
  margin:0;
  & span{
    font-weight: 800;
  }
  &.line{
    span {
      position: relative;
      display: inline;
      font-weight: 800;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        background-color: #7dffef;
        height: 35%;
        z-index: -1;
        transition: 0.2s;
      }
      &:hover {
        &::after {
          height: 100%;
        }
      }
    }
  }
}

h1{
  font-size: 48px;
  line-height: 64px;
  ${media.tablet}{
    font-size: 36px;
    line-height: 48px;
  }
}

h2{
  font-size: 36px;
  line-height: 48px;
  ${media.tablet}{
    font-size: 32px;
    line-height: 44px;
  }
}

h3{
  font-size: 32px;
  line-height: 44px;
}

h4{
  font-size: 26px;
  line-height: 38px;
}

h5{
  font-size: 22px;
  line-height: 34px;
}

h6{
  font-size: 18px;
}

p{
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  &.subtitle{
    font-size: 22px;
    line-height: 34px;
    margin-top: 32px;
    margin-bottom: 0;
    ${media.mobile} {
      margin-top: 24px;
      font-size: 20px;
      line-height: 30px;
    }
  }
  & strong{
    font-weight: 700;
  }
}

@font-face {
  font-family: "icomoon";
  src: url(${arrowDropdown}) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'searchicon';
  src: url(${searchicon}) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

`;

export default GlobalStyle;
