import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Link from 'components/ui/link';
import * as Styled from './styled';

const ACCEPT_PP_COOKIE = '__lsw_accepted_cookies2';
const COOKIE_DOMAIN =
  process.env.GATSBY_HOST === 'http://localhost:8000' ? 'localhost' : '.livesession.io';

const CookieBar: React.FC = () => {
  const [isBarActive, setCookieBar] = useState(true);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    const acceptedCookies = Cookies.get(ACCEPT_PP_COOKIE);

    if (!acceptedCookies || acceptedCookies !== '1') {
      timeout = setTimeout(() => {
        setCookieBar(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, []);

  const acceptPrivacyPolicy = () => {
    Cookies.set(ACCEPT_PP_COOKIE, '1', {
      path: '/',
      domain: COOKIE_DOMAIN,
      expires: 10000,
    });
    setCookieBar(true);
  };

  return (
    <>
      {!isBarActive && (
        <Styled.Banner>
          <Styled.Message>
            We use cookies
            <span className="hide-mobile">
              {' '}
              to tailor your experience and measure website performance
            </span>
            . Click to{' '}
            <Link className="cookie-learn-more" href="/privacy-policy/">
              learn more
            </Link>
          </Styled.Message>
          <Styled.AcceptButton onClick={acceptPrivacyPolicy}>Got it</Styled.AcceptButton>
        </Styled.Banner>
      )}
    </>
  );
};

export default CookieBar;
