import React, { useEffect } from 'react';
import Layout from 'components/layout/layout';
import styled from 'styled-components';
import SideBar from 'components/sideBar/sideBar';
import Content from 'components/content/content';
import { media } from 'theme/breakpoints';
import { fetchIntegrationCategory } from 'api/api';
import { useQuery } from 'react-query';
import Loader from 'components/ui/loading';
import { formatIntegrations } from 'hooks/useFormattedIntegrations';
import { navigate } from 'gatsby';
import compact from 'lodash/compact';

export const Header = styled.div`
  text-align: center;
  margin-top: 70px;
  ${media.tablet} {
    margin-top: 18px;
  }
  & p {
    margin: 32px auto 0;
    font-size: 22px;
    line-height: 34px;
    ${media.tablet} {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const GridBox = styled.div`
  display: grid;
  padding-bottom: 0;
  max-width: 1600px;
  margin: 0 auto;
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 48px;
    padding-bottom: 80px;
  }
`;

const Section = styled.section`
  padding-bottom: 0;
  padding-top: 0;
  display: flex;
`;

const Integrations: React.FC = () => {
  const { isLoading, data } = useQuery(`fetch-integrations`, () => fetchIntegrationCategory(''), {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });
  const formattedIntegration = data && formatIntegrations(data);

  useEffect(() => {
    window.parent.postMessage(window.location.pathname, `${process.env.GATSBY_APP_URL}`);
    const handleMessage = (e: MessageEvent) => {
      console.log(e.data);
      if (typeof e.data === 'string' && e.data.length > 0) {
        const message = JSON.parse(e.data);
        sessionStorage.setItem('installedIntegrations', message.integrationsInstalled);
        const link = compact(message.data.split('app-store')).pop() as 'string';
        if (link && link.includes('integrations')) {
          navigate(link);
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage, false);
  }, []);

  return (
    <Layout
      metaTitle="Integrations Marketplace"
      metaDescription="Get even more out of LiveSession. Browse available integrations with other tools. Improve analytics, customer support, and more."
      canonical="/"
    >
      {isLoading || !formattedIntegration ? (
        <Loader fullScreen />
      ) : (
        <Section>
          <SideBar activeCategory="All integrations" integrations={formattedIntegration} />
          <GridBox>
            <Content
              activeCategory="All integrations"
              hideTitle
              isLoading={isLoading}
              data={formattedIntegration}
            />
          </GridBox>
        </Section>
      )}
    </Layout>
  );
};

export default Integrations;
