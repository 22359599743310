import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import defaultCover from 'images/defaultCover.png';
import defaultCoverIntegrations from 'images/marketplace/covers/defaultCoverIntegrations.png';

interface SEOprops {
  description: string;
  title: string;
  noIndex?: boolean;
  extraTags?: Array<JSX.Element> | undefined;
  canonical: string;
  image?: string;
}

const SEO: React.FC<SEOprops> = ({ description, title, extraTags, canonical, image }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppId
            }
          }
        }
      }
    `,
  );
  const defaultImage = getSrc(defaultCover);

  const metaTitle = title || siteMetadata.title;
  const metaDescription = description || siteMetadata.description;

  const hrefCanonical = `${process.env.GATSBY_HOST}${canonical}`;

  const srcDefaultIntegrations = getSrc(defaultCoverIntegrations);

  const defaultIntegrationsCover =
    canonical && canonical.split('/')[1] === 'integrations' && srcDefaultIntegrations;

  const img = image || defaultIntegrationsCover || defaultImage;

  const imageLink = `${process.env.GATSBY_HOST}${img}`;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={title}
        titleTemplate={
          canonical
            ? canonical.includes('blog') || canonical === '/'
              ? undefined
              : `%s | LiveSession`
            : `%s | LiveSession`
        }
      >
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta name="image" content={imageLink} />
        {canonical && <link rel="canonical" href={hrefCanonical} />}

        {/* OpenGraph tags */}
        {canonical && <meta property="og:url" content={hrefCanonical} />}
        {canonical ? (
          canonical.includes('blog/post') ? (
            <meta property="og:type" content="article" />
          ) : (
            <meta property="og:type" content="website" />
          )
        ) : (
          <meta property="og:type" content="website" />
        )}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imageLink} />
        <meta property="og:image:width" content="1287" />
        <meta property="og:image:height" content="685" />
        <meta property="fb:app_id" content={siteMetadata.social.fbAppId} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={siteMetadata.organization.name} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:site" content={siteMetadata.social.twitter} />
        <meta name="twitter:image" content={imageLink} />
        {/* {noIndex && <meta name="robots" content="noindex" />} */}
        <meta name="robots" content="noindex" />
        {extraTags}
      </Helmet>
    </>
  );
};

export default SEO;
