import { Integration, IntegrationType } from 'types/api';
import { FormattedIntegration } from 'types/custom';

export const formatCategory = (category: string): string => {
  switch (category) {
    case 'bug-tracking':
      return 'Bug tracking';
    case 'customer-support':
      return 'Customer support';
    case 'analytics':
      return 'Analytics';
    case 'best-for-developers':
      return 'Developers';
    case 'cms':
      return 'CMS';
    case 'ecommerce':
      return 'E-commerce';
    case 'e-commerce':
      return 'E-commerce';
    case 'best-for-ux-designers':
      return 'UX Designers';
    case 'best-for-marketers':
      return 'Marketers';
    case 'best-for-support':
      return 'Support';
    case 'most-popular':
      return 'Most popular';
    case 'new':
      return 'New';
    case 'surveys':
      return 'Surveys';
    case 'for-ux-designers':
      return 'UX Designers';
    case 'for-marketers':
      return 'Marketers';
    case 'for-support':
      return 'Support';
    case 'for-developers':
      return 'Developers';
    default:
      return category || '';
  }
};

export const formatIntegrationToCard = (
  integration: IntegrationType | Integration,
): FormattedIntegration => {
  const {
    install_url,
    main_category,
    icon,
    integration_id,
    short_description,
    categories,
    name,
    tutorial_url,
  } = integration;
  const formattedSlug = `/integrations/${integration_id}/`;

  return {
    link: formattedSlug,
    integrationLink: install_url.replace('$APP_URL', ''),
    helpLink: tutorial_url.replace('$WEBSITE_URL', `${process.env.GATSBY_WEBSITE_URL}`),
    activeCategory: main_category,
    icon,
    title: name,
    short_description,
    madeBy: 'LiveSession',
    categories,
  };
};
