import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Collapse } from 'reactstrap';
import { data } from 'data/marketplace/sidebar';
import AllIntImg from 'images/marketplace/sidebarIcons/allIntegrations.inline.svg';
import arrow from 'images/ui/chevron-down.svg';
import * as Styled from './styled';

interface MobileDropdownProps {
  activeCategory: string;
}

const MobileDropdown: React.FC<MobileDropdownProps> = ({ activeCategory }) => {
  const [currentCategory] = data.flatMap((item) =>
    item.list.filter((cat) => cat.title === activeCategory),
  );
  const [isOpen, setOpen] = useState(false);
  return (
    <Styled.Dropdown>
      <Styled.CurrentCategory
        className={isOpen ? 'active' : undefined}
        onClick={() => setOpen(!isOpen)}
      >
        <div>
          {currentCategory?.image || <AllIntImg />}
          <p className="active">{currentCategory?.title || 'All integrations'}</p>
        </div>
        <img src={arrow} alt="arrow" className="icon" />
      </Styled.CurrentCategory>
      <Collapse isOpen={isOpen}>
        <Styled.List>
          {activeCategory !== 'All integrations' && (
            <Styled.Category>
              <AllIntImg />
              <Link to="/">All Integrations</Link>
            </Styled.Category>
          )}
          {data.map(({ list }) =>
            list.map(({ title, image, link }) => {
              if (currentCategory && title === currentCategory.title) return null;
              return (
                <Styled.Category key={title}>
                  {image}
                  <a href={link}>{title}</a>
                </Styled.Category>
              );
            }),
          )}
        </Styled.List>
      </Collapse>
    </Styled.Dropdown>
  );
};

export default MobileDropdown;
