import axios from 'axios';
import { Integrations, Integration, SimilarIntegrations } from 'types/api';
import compact from 'lodash/compact';

const a = axios.create({
  baseURL: `${process.env.GATSBY_APP_API_URL}`,
});

export const fetchIntegrationCategory = async (categoryID: string): Promise<Integrations> => {
  const { data } = await a.get(`/marketplace/integrations?category=${categoryID}`);
  return data;
};

export const fetchIntegration = async (integrationID: string): Promise<Integration> => {
  const { data } = await a.get(`/marketplace/integrations/${integrationID}`);
  return data;
};

export const fetchSimilarIntegrations = async (
  integrationID: string,
): Promise<SimilarIntegrations> => {
  const { data } = await a.get(`/marketplace/integrations/${integrationID}/similar`);
  return data;
};

export const postMarketplaceVisit = async (): Promise<{ success: boolean }> => {
  const context = compact(window.location.pathname.split('/')).pop();
  const { data } = await a.post(`/marketplace/event`, {
    context,
    name: 'marketplace.visit',
  });
  return data;
};

export const postMarketplaceClickedInstall = async (): Promise<{ success: boolean }> => {
  const context = compact(window.location.pathname.split('/')).pop();
  const { data } = await a.post(`/marketplace/event`, {
    context,
    name: 'marketplace.clicked.install',
  });
  return data;
};
