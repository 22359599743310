import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { HitsProvided, Hit, BasicDoc } from 'react-instantsearch-core';
import CustomHighlight from './customHighlight';

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledA = styled.a`
  width: 100%;
  background-color: #fff;
  &:hover {
    background-color: #f6f8f9;
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledBox = styled.li`
  display: flex;
  min-height: 110px;
  align-items: center;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e4e5e8;
  p:nth-child(1) {
    span {
      font-size: 16px;
      font-weight: 800;
      color: #0a4ed6;
    }
  }
  p:nth-child(2) {
    font-weight: 400;
    color: #000;
    margin-top: 8px;
    mark {
      font-weight: 400;
    }
  }
`;

type Props = HitsProvided<BasicDoc>;

const HitsComp: React.FC<Props> = ({ hits }) => {
  return (
    <StyledUl>
      {hits.map((hit: Hit) => (
        <StyledA href={hit.link} className="articlepreview" key={hit.link}>
          <StyledBox>
            <CustomHighlight hit={hit} attribute="title" />
            <CustomHighlight hit={hit} attribute="type" />
          </StyledBox>
        </StyledA>
      ))}
    </StyledUl>
  );
};
const CustomHits = connectHits<Props, BasicDoc>(HitsComp);

export default CustomHits;
