import React, { useState, useEffect, ReactNode } from 'react';
import { Link } from 'gatsby';
import MainBanner from 'components/banner/main/main';
import { FormattedIntegrations } from 'hooks/useFormattedIntegrations';
import Loader from 'components/ui/loading';
import compact from 'lodash/compact';
import InstalledApps from 'components/installedApps/installedApps';
import Card from '../card/card';
import * as Styled from './styled';

interface ContentProps {
  activeCategory: string;
  children?: ReactNode;
  hideTitle?: boolean;
  data: FormattedIntegrations[];
  isLoading: boolean;
}

const Content: React.FC<ContentProps> = ({
  children,
  activeCategory,
  hideTitle,
  isLoading,
  data,
}) => {
  const [currentCategory, setCurrentCategory] = useState('');

  useEffect(() => {
    if (currentCategory !== activeCategory) {
      setCurrentCategory(activeCategory);
    }
  }, [activeCategory, currentCategory]);

  // useEffect(() => {

  // })

  const filterCards = () => {
    const cards =
      data &&
      compact(
        data.flatMap(
          (card) =>
            card.categories &&
            card.categories.map((item: string) => {
              if (item === activeCategory) {
                return card;
              }
              return null;
            }),
        ),
      );
    if (activeCategory === 'All integrations') {
      return (
        data &&
        data.map((card) => (
          <Card key={card.title} {...card} activeCategory={card.main_category} as={Link} />
        ))
      );
    }

    return (
      cards &&
      cards.map(
        (card) =>
          card && <Card key={card.title} {...card} activeCategory={card.main_category} as={Link} />,
      )
    );
  };
  return (
    <Styled.Section className="container">
      <InstalledApps />
      <Styled.Main>
        {children || <MainBanner />}
        {!hideTitle && <h2>{activeCategory}</h2>}
        <Styled.GridBox hideTitle={hideTitle} className={isLoading ? 'loading' : ''}>
          {isLoading ? <Loader /> : filterCards()}
        </Styled.GridBox>
      </Styled.Main>
    </Styled.Section>
  );
};

export default Content;
