import React from 'react';
import * as Styled from './styled';

const InstalledApps: React.FC = () => {
  const moveToApps = () => {
    window.parent.postMessage(
      `${process.env.GATSBY_APP_URL}/app/settings/integrations?tab=installed`,
      `${process.env.GATSBY_APP_URL}`,
    );
  };

  const installed = sessionStorage.getItem('installedIntegrations');

  return (
    <Styled.InstalledApps onClick={moveToApps}>
      <p>Apps & Integrations</p>
      <a href="/#">{`Installed apps ${installed ? `(${installed})` : ''}`}</a>
    </Styled.InstalledApps>
  );
};

export default InstalledApps;
