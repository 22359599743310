import { frontmatter } from 'data/marketplace/integrations';
import { IntegrationType, Integrations } from 'types/api';

export interface IntegrationProps {
  tutorial_url: string;
  link: string;
  id: string;
  icon: string;
  desc: string;
  main_category: string;
}

export interface FullIntegrationProps extends IntegrationProps {
  title: string;
  madeBy: string;
  cover: string;
  categories: Array<string>;
}

export interface FormattedIntegrations extends Omit<IntegrationType, 'categories'> {
  title: string;
  madeBy: string;
  cover: string;
  categories: Array<string> | [];
  link: string;
}

export const formatIntegrations = (data: Integrations): FormattedIntegrations[] => {
  const formattedIntegrations = data.integrations.map((node: IntegrationType) => {
    const { tutorial_url, slug, name } = node;
    const formattedSlug = `/integrations/${slug}/`;
    const findCategories = frontmatter.items.find(({ link }) => link === formattedSlug);
    return {
      ...node,
      tutorial_url: tutorial_url.replace('$WEBSITE_URL', ''),
      madeBy: 'LiveSession',
      link: formattedSlug,
      title: name,
      categories: findCategories?.categories || [],
      cover: findCategories?.cover || '',
    };
  });
  return formattedIntegrations;
};
