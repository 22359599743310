import React, { ReactNode } from 'react';
import styled, { keyframes, CSSProperties } from 'styled-components';
import { useSourceParams } from 'hooks/useSourceParams';

const loading = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface ButtonProps {
  as?: React.ElementType | string;
  href?: string;
  children: ReactNode | string;
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLElement>) => void);
  small?: boolean;
  secondary?: boolean;
  center?: boolean;
  type?: string;
  sourceID?: string;
  signUp?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  style?: CSSProperties;
  target?: string;
  rel?: string;
  reversed?: boolean;
  withLoading?: boolean;
}

export const StyledButton = styled.a<ButtonProps>`
  background-color: ${({ secondary, reversed }) =>
    secondary ? (reversed ? '#fff' : '#000') : reversed ? '#fff' : '#0A4ED6'};
  padding: ${({ small }) => (small ? '7px 20px' : '12px 28px')};
  border: ${({ secondary }) => (secondary ? '2px solid #000' : '2px solid #0A4ED6')};
  border-radius: 4px;
  transition: 0.2s ease;
  max-height: ${(props) => (props.small ? '38px' : '48px')};
  font-size: 16px;
  color: ${({ secondary, reversed }) =>
    secondary ? (reversed ? '#000' : '#fff') : reversed ? '#0A4ED6' : '#fff'};
  font-weight: 800;
  text-align: center;
  cursor: pointer;
  min-width: 140px;
  outline: 0;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  margin: ${(props) => (props.center ? '0 auto' : 0)};
  line-height: normal;
  &:focus {
    color: #fff;
  }
  &:hover {
    color: ${({ secondary, reversed }) =>
      secondary ? (reversed ? '#fff' : '#000') : reversed ? '#fff' : '#0A4ED6'};
    background-color: ${({ secondary, reversed }) =>
      secondary ? (reversed ? '#000' : 'transparent') : reversed ? '#0A4ED6' : 'transparent'};
    outline: 0;
    text-decoration: none;
  }
  &:disabled {
    background-color: #bfbfbf;
    border-color: #bfbfbf;
    &:hover {
      color: white;
      box-shadow: none;
      transform: none;
      background-color: #dfdfe2;
      border-color: #dfdfe2;
    }
    & .lds-ring {
      display: inline-flex;
      transform: translate(-16px, 2px);
      opacity: 1;
    }
  }
  & .lds-ring {
    display: none;
    position: relative;
    width: 16px;
    height: 16px;
    transition: 0.2s ease;
    opacity: 0;
    transition: all 0.2s ease;
    transform: translate(0, 2px);
    & div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      margin: 0;
      border: 2px solid #fff;
      border-radius: 50%;
      animation: ${loading} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
`;

const Button: React.FC<ButtonProps> = ({
  as,
  href,
  children,
  onClick,
  small,
  secondary,
  center,
  type,
  sourceID,
  signUp,
  className,
  disabled,
  id,
  style,
  target,
  rel,
  reversed,
  withLoading,
}) => {
  const { link } = useSourceParams(sourceID);
  return (
    <StyledButton
      as={as}
      href={signUp ? link : href}
      onClick={onClick}
      small={small}
      secondary={secondary}
      center={center}
      type={type}
      className={className}
      disabled={disabled}
      id={id}
      style={style}
      target={target}
      rel={rel}
      reversed={reversed}
    >
      {withLoading && (
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {children}
    </StyledButton>
  );
};

export default Button;
