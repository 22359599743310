import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Category = styled.div`
  margin-bottom: 40px;
  ${media.desktop} {
    margin-bottom: 32px;
  }
  ${media.tablet} {
    display: none;
  }
  & h3 {
    font-weight: 800;
    margin-bottom: 24px;
    font-size: 20px;
    line-height: normal;
  }
`;

export const Aside = styled.aside`
  padding: 45px;
  border-right: 1px solid #e5e5e5;
  background-color: #f6f8f9;
  height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
  width: 356px;
  min-width: 356px;
  .react-autosuggest__suggestions-container--open {
    max-height: 300px !important;
    overflow-y: auto;
    width: 100% !important;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, 52px);
    z-index: 15;
  }
  ${media.tablet} {
    border-right: none;
    .react-autosuggest__suggestions-container--open {
      width: 100%;
    }
  }
  .react-autosuggest__suggestion {
    list-style: none;
    display: list-item;
  }

  .react-autosuggest__suggestions-list {
    padding: 0;
    margin: 0;
  }

  .react-autosuggest__suggestion--highlighted .articlepreview {
    background-color: #f6f8f9;
  }

  ${Category}:last-child {
    margin-bottom: 0;
  }
`;

export const AllIntegrations = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  &:hover {
    & svg {
      .blue {
        fill: #7dffef;
      }
    }
  }
  & svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    &.active {
      .blue {
        fill: #7dffef;
      }
    }
  }
  & a {
    display: block;
    font-size: 14px;
    line-height: normal;
    color: #000;
    transition: none;
    cursor: pointer;
    &.active {
      font-weight: 800;
      &:hover {
        text-decoration: none;
      }
    }
  }
  ${media.tablet} {
    display: none;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  & li {
    cursor: pointer;
    & div {
      display: flex;
      align-items: center;
    }
    & a {
      display: flex;
      align-items: center;
      color: #000;
      & div {
        & svg {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
        &.active {
          & svg .yellow {
            fill: #ffe478;
          }
          & svg .blue {
            fill: #7dffef;
          }
          & svg .purple {
            fill: #8765f9;
          }
        }
      }
      & p {
        display: block;
        font-size: 14px;
        line-height: normal;
        color: #000;
        padding: 0;
        transition: none;
        cursor: pointer;
        margin: 0;
        &.active {
          font-weight: 800;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &:hover {
      & div {
        & svg .yellow {
          fill: #ffe478;
        }
        & svg .blue {
          fill: #7dffef;
        }
        & svg .purple {
          fill: #8765f9;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &:last-child {
      margin-bottom: 32px;
    }
  }
`;

export const Label = styled.label`
  color: #a9a9a9;
  margin: 0;
  font-family: 'searchicon' !important;
  &::before {
    content: '\\e900';
    color: #000;
    position: absolute;
    padding: 16px;
    transform: translateY(-32px);
    line-height: 100%;
  }
`;

export const DivInput = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  margin: 0 auto 48px;
  background-color: #fff;
  &:focus-within {
    ${Label} {
      &::before {
        color: #0a4ed6;
      }
    }
  }
  @media (max-width: 766px) {
    width: 95%;
  }
`;

export const Input = styled.input`
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding-left: 48px;
  border: 2px solid #000;
  border-radius: 4px;
  font-size: 16px;
  -webkit-appearance: none;
  &:focus {
    border: 2px solid #0a4ed6;
  }
  &::placeholder {
    color: #808080;
    font-size: 16px;
  }
`;
