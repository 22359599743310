import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { HighlightProps } from 'react-instantsearch-core';

const StyledP = styled.p`
  mark {
    font-size: initial;
    font-weight: 900;
    color: #000;
    background-color: #ffe478;
    padding: 0;
  }
  span {
    font-size: 14px;
    margin: 0;
  }
`;

const Highlight: React.FC<HighlightProps> = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  }) as Array<{ value: string; isHighlighted: boolean }>;
  return (
    <StyledP>
      {parsedHit.map(({ isHighlighted, value }) =>
        isHighlighted ? <mark key={value}>{value}</mark> : <span key={value}>{value}</span>,
      )}
    </StyledP>
  );
};

const CustomHighlight = connectHighlight(Highlight);

export default CustomHighlight;
