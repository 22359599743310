import React from 'react';
import CustomLink from 'components/ui/link';
import Button from 'components/ui/button';
import { formatCategory } from 'helpers/integrationFormat';
import { CardProps } from 'types/custom';
import { postMarketplaceClickedInstall } from 'api/api';
import * as Styled from './styled';

const IntegrationCard: React.FC<CardProps> = ({
  title,
  icon,
  link,
  integrationLink,
  activeCategory,
  integrationPage,
  as,
  linkTag,
  short_description,
  helpLink,
}) => {
  const badgeColor = (text: string) => {
    switch (text) {
      case 'Customer support':
        return '#8765F9';
      case 'Analytics':
        return '#FFE478';
      case 'E-commerce':
        return '#7DFFEF';
      case 'CMS':
        return '#FFE478';
      case 'Developers':
        return '#FFE478';
      case 'UX Designers':
        return '#FFE478';
      case 'Marketers':
        return '#7DFFEF';
      case 'Most popular':
        return '#8765F9';
      case 'New':
        return '#8765F9';
      case 'Bug tracking':
        return '#7DFFEF';
      case 'Surveys':
        return '#FFE478';
      default:
        return undefined;
    }
  };

  const category = formatCategory(activeCategory || '');

  const handleInstallNow = (slug: string) => {
    window.parent.postMessage(
      `${process.env.GATSBY_APP_URL}${slug}`,
      `${process.env.GATSBY_APP_URL}`,
    );
  };

  const handleIntegrationBtnClick = () => postMarketplaceClickedInstall();

  return (
    <Styled.Card
      badgecolor={badgeColor(category)}
      badge={category}
      white={category === 'Customer support' || category === 'Most popular' ? '#fff' : '#000'}
      href={linkTag ? undefined : link}
      integrationpage={integrationPage}
      to={link}
      as={as}
    >
      <div>
        <img src={icon} alt={`${title} - logo`} title={title} />
        <h4>{title}</h4>
        <p>{short_description}</p>
      </div>
      {integrationPage ? (
        integrationLink && integrationLink.length > 0 ? (
          <CustomLink
            withArrow
            tag={linkTag || 'p'}
            href={linkTag ? helpLink : undefined}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </CustomLink>
        ) : (
          ''
        )
      ) : (
        <CustomLink
          withArrow
          tag={linkTag || 'p'}
          href={linkTag ? link : undefined}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </CustomLink>
      )}
      {integrationPage &&
        (integrationLink ? (
          <Button
            href={`${process.env.GATSBY_APP_URL}${integrationLink}`}
            className={!integrationLink && link ? 'btn-only' : undefined}
            onClick={(e) => {
              e.stopPropagation();
              handleIntegrationBtnClick();
              handleInstallNow(integrationLink);
            }}
          >
            Install now
          </Button>
        ) : (
          <Button
            href={integrationLink ? `${process.env.GATSBY_APP_URL}${integrationLink}` : helpLink}
            className={!integrationLink && link ? 'btn-only' : undefined}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleIntegrationBtnClick}
          >
            Install now
          </Button>
        ))}
    </Styled.Card>
  );
};

export default IntegrationCard;
