import React, { ElementType, ReactNode } from 'react';
import { media } from 'theme/breakpoints';
import styled, { CSSProperties } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import arrowIcon from 'images/ui/icon_arrow.svg';
import arrowIconLight from 'images/ui/icon_arrow_lightblue.svg';
import { useSourceParams } from 'hooks/useSourceParams';

interface LinkProps {
  target?: string;
  rel?: string;
  children: ReactNode | string;
  style?: CSSProperties;
  className?: string;
  withArrow?: boolean;
  lightblue?: boolean;
  sourceID?: undefined;
  tag?: string | ElementType;
  href?: string;
  as?: ReactNode | string;
  to?: string;
}

type IsSignupLink =
  | {
      signUp: true;
      sourceID?: string;
    }
  | {
      signUp?: false;
      sourceID?: never;
    };

type CustomLink = LinkProps & IsSignupLink;

type Props = CustomLink;

export const StyledLink = styled.a<LinkProps>`
  color: #0a4ed6;
  display: inline-block;
  font-weight: ${(props) => (props.withArrow ? 800 : 400)};
  cursor: pointer;
  ${media.mobile} {
    font-size: 16px;
  }
  &::after {
    content: ${(props) =>
      props.withArrow ? (props.lightblue ? `url(${arrowIconLight})` : `url(${arrowIcon})`) : null};
    display: inline-block;
    clear: both;
    margin-left: 16px;
    transition: 0.2s ease;
  }
  &.with-arrow {
    font-weight: 800;
    &::after {
      content: ${(props) => (props.lightblue ? `url(${arrowIconLight})` : `url(${arrowIcon})`)};
    }
  }
  &:hover {
    text-decoration: underline;
    color: #0a4ed6;
    &::after {
      transform: translateX(8px);
    }
  }
`;

const Link: React.FC<Props> = ({
  href,
  target,
  rel,
  children,
  style,
  className,
  withArrow,
  tag,
  lightblue,
  sourceID,
  signUp,
}) => {
  const internal = href && href.charAt(0) === '/' && !target && !rel;

  const { link } = useSourceParams(sourceID);

  return (
    <>
      {internal && !tag ? (
        <StyledLink
          as={GatsbyLink}
          to={(signUp && link) || href || '/'}
          style={style}
          className={[className, withArrow && 'with-arrow'].join(' ')}
          lightblue={lightblue}
        >
          {children}
        </StyledLink>
      ) : (
        <StyledLink
          className={className}
          style={style}
          href={signUp ? link : href}
          target={target}
          rel={rel}
          withArrow={withArrow}
          as={tag}
          lightblue={lightblue}
        >
          {children}
        </StyledLink>
      )}
    </>
  );
};

export default Link;
