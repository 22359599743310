import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, Highlight, connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Hit as HitProps } from 'react-instantsearch-core';
import { OnSuggestionSelected } from 'react-autosuggest';
import { FormattedIntegrations } from 'hooks/useFormattedIntegrations';
import Autocomplete from './autocomplete';
import CustomHits from './customHits';

const StyledContainer = styled.div`
  position: relative;
  z-index: 20;
`;

const VirtalSearchBox = connectSearchBox(() => null);

const index = process.env.GATSBY_ALGOLIA_INTEGRATIONS || 'INTEGRATIONS_LABS';

const searchClient = algoliasearch('G5L9QWEP2F', 'bf1653634fbcabb418d2f166f607c083');

const Hit: React.FC<HitProps> = ({ hit }) => {
  return (
    <div>
      <Highlight attribute="name" hit={hit} />
    </div>
  );
};

export interface HitRecord {
  categories?: string[];
  desc?: string;
  title?: string;
  madeBy?: string;
  link?: string;
  objectID?: string;
}

const SearchBar: React.FC<{ integrations: FormattedIntegrations[] }> = ({ integrations }) => {
  const [query, setQuery] = useState('');

  const onSuggestionSelected: OnSuggestionSelected<HitRecord> = (_event, data) => {
    const { title, link } = data.suggestion;
    if (link && title) {
      navigate(link);
      setQuery(title);
    }
  };

  return (
    <StyledContainer>
      <InstantSearch indexName={index} searchClient={searchClient}>
        <Configure hitsPerPage={5} />
        <Autocomplete
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionCleared={() => setQuery('')}
          integrations={integrations}
        />
      </InstantSearch>

      <InstantSearch indexName={index} searchClient={searchClient}>
        <VirtalSearchBox defaultRefinement={query} />
        {/* @ts-expect-error: Package does not support custom hits component  */}
        {query && <CustomHits hitComponent={Hit} />}
      </InstantSearch>
    </StyledContainer>
  );
};

export default SearchBar;
