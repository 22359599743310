import React, { ReactNode } from 'react';
import MostPopular from 'images/marketplace/sidebarIcons/mostPopular.inline.svg';
import New from 'images/marketplace/sidebarIcons/new.inline.svg';
import Marketers from 'images/marketplace/sidebarIcons/marketers.inline.svg';
import UxDesigners from 'images/marketplace/sidebarIcons/uxDesigners.inline.svg';
import Support from 'images/marketplace/sidebarIcons/support.inline.svg';
import Developers from 'images/marketplace/sidebarIcons/developers.inline.svg';
import Analytics from 'images/marketplace/sidebarIcons/analytics.inline.svg';
import CMS from 'images/marketplace/sidebarIcons/cms.inline.svg';
import Ecommerce from 'images/marketplace/sidebarIcons/ecommerce.inline.svg';
import CustomerSupport from 'images/marketplace/sidebarIcons/customerSupport.inline.svg';
import BugTracking from 'images/marketplace/sidebarIcons/bugTracking.inline.svg';
import Surveys from 'images/marketplace/sidebarIcons/surveys.inline.svg';

interface dataType {
  category: string;
  list: Array<{
    title: string;
    image: ReactNode;
    link: string;
  }>;
}

export const data: dataType[] = [
  {
    category: 'Featured',
    list: [
      {
        title: 'Most popular',
        image: <MostPopular />,
        link: '/integrations/most-popular/',
      },
      {
        title: 'New',
        image: <New />,
        link: '/integrations/new/',
      },
    ],
  },
  {
    category: 'Best for',
    list: [
      {
        title: 'Marketers',
        image: <Marketers />,
        link: '/integrations/for-marketers/',
      },
      {
        title: 'UX Designers',
        image: <UxDesigners />,
        link: '/integrations/for-ux-designers/',
      },
      {
        title: 'Developers',
        image: <Developers />,
        link: '/integrations/for-developers/',
      },
      {
        title: 'Support',
        image: <Support />,
        link: '/integrations/for-support/',
      },
    ],
  },
  {
    category: 'Categories',
    list: [
      {
        title: 'Analytics',
        image: <Analytics />,
        link: '/integrations/analytics/',
      },
      // {
      //   title: 'CRM',
      //   image: <CRM />,
      //   link: '/integrations/crm/',
      // },
      {
        title: 'CMS',
        image: <CMS />,
        link: '/integrations/cms/',
      },
      {
        title: 'E-commerce',
        image: <Ecommerce />,
        link: '/integrations/e-commerce/',
      },
      {
        title: 'Bug tracking',
        image: <BugTracking />,
        link: '/integrations/bug-tracking/',
      },
      {
        title: 'Customer support',
        image: <CustomerSupport />,
        link: '/integrations/customer-support/',
      },
      {
        title: 'Surveys',
        image: <Surveys />,
        link: '/integrations/surveys/',
      },
    ],
  },
];

export default data;
