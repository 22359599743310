import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import { data } from 'data/marketplace/sidebar';
import AllIntImg from 'images/marketplace/sidebarIcons/allIntegrations.inline.svg';
import { FormattedIntegrations } from 'hooks/useFormattedIntegrations';
import * as Styled from './styled';
import SearchBar from '../searchBar/searchBar';
import MobileDropdown from './mobileDropdown/mobileDropdown';

interface SideBarProps {
  activeCategory: string;
  integrations: FormattedIntegrations[];
}

interface ListProps {
  title: string;
  image: ReactNode;
  link: string;
}
interface DataProps {
  category: string;
  list: ListProps[];
}

const SideBar: React.FC<SideBarProps> = ({ activeCategory, integrations }) => {
  return (
    <Styled.Aside>
      <div>
        <SearchBar integrations={integrations} />
      </div>
      <MobileDropdown activeCategory={activeCategory || 'All integrations'} />
      <Styled.AllIntegrations>
        <AllIntImg className={activeCategory === 'All integrations' ? 'active' : undefined} />
        <Link className={activeCategory === 'All integrations' ? 'active' : undefined} to="/">
          All integrations
        </Link>
      </Styled.AllIntegrations>
      {data.map(({ category, list }: DataProps) => {
        return (
          <Styled.Category key={category}>
            <h3>{category}</h3>
            <Styled.List>
              {list.map(({ title, image, link }) => (
                <li key={title}>
                  <Link className={activeCategory === title ? 'active' : undefined} to={link}>
                    <div className={activeCategory === title ? 'active' : undefined}>{image}</div>
                    <p>{title}</p>
                  </Link>
                </li>
              ))}
            </Styled.List>
          </Styled.Category>
        );
      })}
    </Styled.Aside>
  );
};

export default SideBar;
